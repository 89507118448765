
.mainContainer {
    font-family: 'Atyp Display';
    font-weight: normal;
    font-style: normal;

    background: #000000;
    width: 99.86vw;
    height: 45rem;
    color: white;
    position: relative;
    z-index: 0;
    overflow: hidden;

}
.container {
    position: relative;
    display: flex;
    height: 100%;
    z-index: 1;
}
.backgroundImage {
    position: absolute;
    left: 30px;
    top: 35px;
    height: 630px;
    width: 640px;
    background-image: url("/images/banner-gradient-bg.png");
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 0;
}

.title {
    position: absolute;
    top: 90px;
    left: 130px;
    width: 60%;
    font-style: normal;
    font-weight: 300;
    font-size: 58px;
    line-height: 100%;

    text-transform: uppercase;
}
.cards {
    position: relative;
    display: flex;
    top: 230px;
    left: 130px;
}
.imgContainer {
    position: relative;
    width: 12rem;
    margin-right: 1rem;
}

.hollowText {
    color: transparent;
    -webkit-text-stroke: 1px #525252;
    line-height: 80.02%;
}
.bottomBannerText {
    position: absolute;
    bottom: 2rem;
    left: -60px;
    font-size: 130px;

    text-transform: uppercase;
}
.button {
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;

    font-size: 13px;

    width: 145px;
    height: 45px;


    background: #FFFFFF;

    border: 1px solid #FFFFFF;
    border-radius: 40px;

}
.discordContainer {
    margin: 16rem 0 0 18rem;
}
.discordDesc {
    margin-bottom: 1.5rem;
    width: 20rem;
    font-size: 12px;
}
.sbt {
    position: absolute;
    top: 90px;
    right: 50px;

    font-size: 80px;
    transform: rotate(90deg);
}

@media screen and (max-width: 1396px) {
    .title {
        width: 70%;
    }
}

@media screen and (max-width: 1245px) {
    .title {
        left: 110px;
    }
    .backgroundImage {
        width: 600px;
    }
    .cards {
        left: 110px;
    }
    .discordContainer {
        margin: 16rem 0 0 13rem;
    }
}

@media screen and (max-width: 1164px) {
    .mainContainer {
        height: 56em;
    }
    .title {
        width: 72%;
        font-size: 55px;
    }
    .discordContainer {
        position: absolute;
        top: 310px;
        left: -100px;
    }
}

@media screen and (max-width: 956px){
    .mainContainer {
        height: 46rem;
    }
    .title {
        top: 10px;
        left: 30px;
        width: 95%;
        font-size: 35px;

    }
    .backgroundImage {
        position: absolute;
        left: 30px;
        top: 100px;
        width: 100%;
        height: 500px;
    }
    .cards {
        top: 120px;
        left: 50px;
    }
    .discordContainer {
        position: absolute;
        top: 190px;
        left: -150px;
    }
    .bottomBannerText {
        font-size: 80px;
    }
    .sbt {
        visibility: hidden;
    }
}

@media screen and (max-width: 675px){
    .title {
        top: 10px;
        font-size: 35px;
    }
    .backgroundImage {
        position: absolute;
        left: 30px;
        top: 130px;
        width: 90%;
        height: 550px;
    }
    .cards {
        top: 105px;
        left: 50px;
    }
    .hideCard {
        display: none;
    }
    .discordContainer {
        top: 160px;
        left: -150px;
    }
    .bottomBannerText {
        font-size: 50px;
    }
    .sbt {
        visibility: hidden;
    }
}

@media screen and (max-width: 500px){
    .mainContainer{
        height: 44rem;
    }
    .title {
        left: 20px;
    }
    .backgroundImage {
        top: 180px;
        left: 20px;

    }
    .imgContainer {
        width: 8rem;
    }
    .cards {
        top: 165px;
        left: 30px;
    }

    .discordContainer {
        left: -180px;
    }
    .bottomBannerText {
        left: -20px;
        font-size: 40px;

    }
    .discordDesc {
        width: 19rem;
    }

}